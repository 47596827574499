<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('xeroInvoicing.actions') | title"></huboo-page-heading>
    <div v-if="isAdmin">
      <v-toolbar class="my-4">
        <v-toolbar-title class="mx-2">{{ $t('xeroInvoice.toolbarTitle') }}</v-toolbar-title>
        <v-btn
          color="primary"
          @click="getXeroHealthCheck"
          class="mx-2"
          :loading="isLoadingHealth"
          data-testid="health-check-button"
        >
          {{ $t('xeroInvoice.healthCheck') }}
        </v-btn>
        <v-row class="ml-6">
          <p class="ma-2" data-testid="company-data">
            {{ $t('xeroInvoice.data') }}: {{ JSON.stringify(companyDetailsValue.data) }}
          </p>
          <p class="ma-2" data-testid="company-timestamp">
            {{ $t('xeroInvoice.lastUpdated') }}: {{ companyDetailsValue.timeStamp }}
          </p>
        </v-row>
      </v-toolbar>

      <xero-invoice-request-form></xero-invoice-request-form>
    </div>
    <div v-else>
      <v-card>
        <v-card-text class="text-subtitle-1">
          <p>{{ $t('xeroInvoice.notAdmin') }}</p>
        </v-card-text>
      </v-card>
    </div>
  </huboo-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ClientMixin from '@/mixins/client'
import XeroInvoiceRequestForm from './XeroInvoiceRequestForm.vue'

export default {
  name: 'XeroInvoicing',
  components: { XeroInvoiceRequestForm },
  mixins: [ClientMixin],
  data() {
    return {
      isLoadingHealth: false,
    }
  },
  computed: {
    ...mapGetters('xeroInvoicing', ['getXeroData']),
    ...mapGetters('core', ['getIsAdmin']),
    isAdmin() {
      return this.getIsAdmin
    },
    companyDetailsValue() {
      return this.getXeroData.health
    },
  },
  methods: {
    ...mapActions('xeroInvoicing', ['healthCheck', 'clearHealthData']),
    async getXeroHealthCheck() {
      this.isLoadingHealth = true
      try {
        await this.healthCheck()
      } catch (error) {
        console.error('Error getting health check:', error)
      } finally {
        this.isLoadingHealth = false
      }
    },
  },
  mounted() {
    this.clearHealthData()
  },
}
</script>

<style lang="scss" scoped>
.banner {
  margin-bottom: 1.25rem;
}
.filter-row {
  display: flex;
  flex-flow: row wrap;
}
.break {
  flex-basis: 100%;
  height: 0;
}
</style>
