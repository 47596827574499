<template>
  <v-card class="my-4" elevation="5">
    <v-card-title>{{ $t('xeroInvoice.title') }}</v-card-title>

    <v-row>
      <v-col cols="6">
        <v-form ref="form" v-model="valid" lazy-validation class="modal-form pa-6">
          <v-menu
            v-model="menuFromDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                format="yyyy-MM-dd"
                :label="$t('xeroInvoice.fields.fromDate')"
                prepend-inner-icon="calendar_today"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[requiredRule]"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fromDate" @input="menuFromDate = false"> </v-date-picker>
          </v-menu>

          <v-menu
            v-model="menuToDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                format="yyyy-MM-dd"
                :label="$t('xeroInvoice.fields.toDate')"
                prepend-inner-icon="calendar_today"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[requiredRule, toDateAfterFromDateRule]"
              ></v-text-field>
            </template>
            <v-date-picker v-model="toDate" @input="menuToDate = false"> </v-date-picker>
          </v-menu>

          <v-menu
            v-model="menuInvoicedAt"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="invoicedAt"
                format="yyyy-MM-dd"
                :label="$t('xeroInvoice.fields.postingDate')"
                prepend-inner-icon="calendar_today"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[requiredRule, invoicedAtAfterToDateRule]"
              ></v-text-field>
            </template>
            <v-date-picker v-model="invoicedAt" @input="menuInvoicedAt = false"> </v-date-picker>
          </v-menu>

          <v-select
            v-model="clientTypeFrequency"
            :items="clientTypes"
            :label="$t('xeroInvoice.fields.selectClientType')"
            outlined
            :rules="[requiredRule]"
          ></v-select>

          <div class="">
            <v-btn color="warning" class="ma-4" @click="resetForm">
              {{ $t('xeroInvoice.fields.resetButton') }}
            </v-btn>
            <v-btn :disabled="!valid" color="success" class="ma-4" @click="validateAndSubmit">
              {{ $t('xeroInvoice.fields.generateInvoiceButton') }}
            </v-btn>
          </div>
        </v-form>
      </v-col>

      <v-col cols="6">
        <v-card-title class="subtitle-1"
          >{{ $t('xeroInvoice.noOfInvoicesGenerated') }}:
          <span class="font-weight-bold mx-2">{{ invoicesGenerated }}</span>
          <span v-if="invoicesGeneratedDateTime"> | {{ invoicesGeneratedDateTime }}</span>
        </v-card-title>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { format } from 'date-fns'
import i18n from '@/boot/i18n'

export default {
  name: 'XeroInvoiceRequestForm',
  components: {},
  data() {
    return {
      valid: false,
      menuFromDate: false,
      menuToDate: false,
      menuInvoicedAt: false,
      fromDate: null,
      toDate: null,
      invoicedAt: null,
      clientTypeFrequency: null,
      clientTypes: [
        { text: 'Weekly', value: 'Weekly' },
        { text: 'Monthly', value: 'Monthly' },
      ],
      invoicesGenerated: null,
      invoicesGeneratedDateTime: null,
      requiredRule: value => !!value || i18n.t('xeroInvoice.validation.requiredField'),
      toDateAfterFromDateRule: value => {
        if (!this.fromDate || !value) return true // Allow validation if dates are missing
        const from = new Date(this.fromDate)
        const to = new Date(value)
        return to >= from || i18n.t('xeroInvoice.validation.toDateAfterFromDate')
      },
      invoicedAtAfterToDateRule: value => {
        if (!this.toDate || !value) return true
        const to = new Date(this.toDate)
        const invoicedAt = new Date(value)
        return invoicedAt >= to || i18n.t('xeroInvoice.validation.postingDateAfterToDate')
      },
    }
  },
  methods: {
    ...mapActions('xeroInvoicing', ['generateInvoices']),
    async validateAndSubmit() {
      // need to await validation
      await this.$refs.form.validate()
      if (!this.valid) {
        return
      }
      const body = {
        data: {
          clientType: this.clientTypeFrequency,
          dateFrom: this.fromDate,
          dateTo: this.toDate,
          invoicedAt: this.invoicedAt,
        },
      }
      const res = await this.generateInvoices(body)

      if (res?.status !== 200) return
      this.invoicesGenerated = res?.data?.data.length ?? 0
      this.invoicesGeneratedDateTime = format(new Date(), 'dd-MMM-yy HH:mm:ss')
      this.resetForm()
    },
    resetForm() {
      // Reset the v-models inside the form manually
      this.fromDate = null
      this.toDate = null
      this.invoicedAt = null
      this.clientTypeFrequency = null

      // Reset form validation
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-form {
  .modal-form__field {
    margin-bottom: 1rem;
  }
}
</style>
